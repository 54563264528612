export const DATA = [
	{
		id: "2",
		title: "Karisma ITB (Keluarga Remaja Islam Salman ITB)",
		slug: "karisma",
		logo: "karisma.png",
		video: "https://www.youtube.com/embed/2krM6RONKKY",
		profile:
			"Karisma (Keluarga Remaja Islam Salman) ITB adalah unit kegiatan mahasiswa yang bergerak pada ranah pembinaan remaja di kota Bandung. Unit kegiatan ini berdiri sejak Maret 1981 menjadikannya sebagai unit kegiatan tertua yang ada di Salman. Ide awal pendiriannya ingin berusaha menarik remaja ke lingkungan masjid agar dapat mengisi hari-harinya dengan kegiatan positif, tidak sekadar kegiatan hura-hura seperti balapan motor di sepanjang jalan Dago yang sedang menjadi tren saat itu. Pada masa tersebut, Masjid Salman menjadi salah satu pusat dakwah remaja di Kota Bandung. Pada tahun 1986, unit kegiatan ini pernah mendapatkan nominasi dari UNICEF dalam “What Works in The World” karena menginisiasi pendidikan berbasis mentoring kepada remaja di kota Bandung.",
		vision:
			"Terbentuknya Generasi Rabbani yang seimbang dalam iman, ilmu, dan amal serta mampu menjadi rahmat bagi sekalian alam.",
		instagram: "https://www.instagram.com/sobatkarisma/",
		activities: [
			{
				title: "Pembinaan Adik",
				desc: "Pembinaan teman – teman SMP-SMA bulanan untuk membentuk generasi Rabbani yang seimbang iman, ilmu dan amal serta menjadi rahmat bagi semesta alam.",
			},
			{
				title: "Simulasi Hakikat Diri Manusia",
				desc: "Kegiatan yang memberikan pemahaman terkait hakikat diri manusia, dikemas dengan alur cerita dari awal penciptaan manusia sampai di alam kubur serta disampaikan dengan berbagai pendekatan kreatif sehingga peserta tidak hanya paham tapi meresap sampai menyentuh hati.",
			},
			{
				title: "Ramadhan with Karisma",
				desc: "Kegiatan yang bertujuan untuk membangun kesadaran terkait pentingnya pendidikan dan sosial serta membentuk karakteristik sebagai orang yang terdidik kepada teman-teman di SMP dengan memanfaatkan momentum bulan Ramadan.",
			},
			{
				title: "Bimbingan Belajar Karisma Learning Centre",
				desc: "Kegiatan yang memfasilitasi teman-teman SMP-SMA dalam belajar tentang mata pelajaran di sekolah seperti matematika, fisika, kimia, biologi, dan sebagainya. Selain dari pada itu peserta difasilitasi juga dalam belajar agama islam oleh para pengajar yang berkaitan dengan apa yang dipelajari.",
			},
			{
				title: "Fokus UTBK",
				desc: "Kegiatan yang memfasilitasi teman - teman yang berniat untuk mengikuti ujian masuk perguruan tinggi negeri melalui SNBT/UTBK.",
			},
			{
				title: "OSN Preparation",
				desc: "Kegiatan yang memfasilitasi teman - teman SMP-SMA yang berniat untuk mengikuti Olimpiade Sains Nasional (OSN).",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Rafli Adila",
		pembina: "Punya ketertarikan membina remaja",
	},
	{
		id: "3",
		title: "MATA Salman ITB",
		slug: "mata",
		logo: "mata.png",
		video: "https://www.youtube.com/embed/HSec3kk-DXQ",
		profile:
			"MATA Salman ITB atau disebut juga Majelis Ta'lim Salman ITB merupakan unit kemahasiswaan yang berada di bawah naungan Masjid Salman ITB dengan fokus memberikan pembinaan keislaman untuk mahasiswa S1. MATA berdiri pada tanggal 19 November 1994.",
		vision:
			"Menciptakan rumah ruhani yang menumbuhkan pemahaman keislaman dan pengembangan diri untuk mempersiapkan mahasiswa sebagai kader penyemai nilai islam.",
		instagram: "https://www.instagram.com/matasalman/",
		activities: [
			{
				title: "Pembinaan Anggota Muda",
				desc: "Pembinaan Anggota Muda merupakan pembinaan dasar sebelum teman - teman menjadi anggota MATA Salman ITB. Kalian semua di dalamnya akan mempelajari dasar keislaman dengan materi adab, dasar aqidah, dasar hukum islam, dan kealquranan. Selain itu ada sesi Kumpul AM yang menjadi tempat para calon anggota MATA Salman ITB untuk berkumpul bersama, berdiskusi suatu permasalahan dalam forum group discussion, dan masih banyak sesi lainnya.",
			},
			{
				title: "Sekolah Quran",
				desc: "Di Sekolah Quran ini kamu akan mempelajari cara baca Al Quran dan juga pengetahuan - pengetahuan yang berkaitan dengan Al Quran seperti bagaimana cara berinteraksi dengan Al Quran, perspektif Al Quran tentang sains / keilmuan lainnya, hingga mentadabburi Al Quran.",
			},
			{
				title: "Kelas Profesi",
				desc: "Kelas ini di khususkan buat teman - teman yang sudah mengikuti kegiatan Pembinaan Anggota Muda. Bagi yang mengikuti kelas profesi ini akan mempelajari lebih dalam tentang bagaimana caranya mensyiarkan keahlian/minat/bidang studi yang di pelajari saat kuliah.",
			},
			{
				title: "Kelas Menulis Artikel",
				desc: "Dalam kelas ini teman - teman akan mempelajari bagaimana cara untuk menulis sebuah artikel ilmiah populer yang akan dibaca oleh publik.",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
			{
				img: "7.jpg",
			},
			{
				img: "8.jpg",
			},
			{
				img: "9.jpg",
			},
		],
		ketua: "Raizar Mahabbatan",
		pembina: "Memiliki rasa keinginan untuk belajar, Mahasiswa/i aktif S1, Berdomisili di bandung dan sekitarnya"
	},
	{
		id: "4",
		title: "PAS ITB",
		slug: "pas",
		logo: "pas.png",
		video: "https://www.youtube.com/embed/sZNAXrqfZWM",
		profile:
			"Pembinaan Anak-anak Salman ITB sebagai wadah pendidikan informal, yang bertujuan membina seluruh anggotanya, yaitu anak - anak, pembina dan orang tua untuk mencapai pribadi muslim yang diridhai Allah, yang memiliki keseimbangan iman, akal dan rasa, serta sehat dan kuat fisiknya, serta mampu menjadi rahmat bagi seluruh alam. Ide pendirian Pembinaan Anak Salman (PAS) berawal dari pengajian rutin yang diadakan oleh beberapa mahasiswa ITB dalam rangka mengisi kegiatan ramadhan kemudian pada tahun 1984 berlanjut hingga menjadi kegiatan mentoring pekanan untuk menjawab kebutuhan anak-anak dalam menanamkan nilai-nilai islam yang disajikan dengan kreatifitas dan metode yang mudah diterima masyarakat luas.",
		vision:
			"Terbentuknya generasi muslim yang diridhoi Allah yang mempunyai keseimbangan fikriyah, jasadiyah, dan ruhiyah, serta menjadi rahmat bagi seluruh alam",
		mission: "Adanya pembinaan berjenjang bagi anak-anak usia 3-12 tahun, yang terdiri dari mentoring BBAQ, mentoring kolosal, dan mentoring club",
		instagram: "https://www.instagram.com/pas_itb/",
		activities: [
			{
				title: "Penerimaan Kakak Baru",
				desc: "Penerimaan kakak baru merupakan gerbang awal kegiatan pembinaan dan kaderisasi bagi calon kakak PAS-ITB.",
			},
			{
				title: "PAS Gathering",
				desc: "PAS Gathering adalah salah satu program kerja yang dirancang oleh divisi internal yang bertujuan untuk membangun rasa kekeluargaan antar anggota serta meningkatkan kedekatan, solidaritas dan kekompakan antar sesama anggota PAS-ITB.",
			},
			{
				title: "Milad PAS",
				desc: "Milad PAS merupakan perayaan ualng tahun PAS-ITB yang bertepatan dengan tahun baru islam yang diisi dengan beragam kegiatan seperti seminar, perlombaan internal, aksi kreatif dan sharing-temu alumni.",
			},
			{
				title: "Mentoring Pembinaan Adik TK dan SD",
				desc: "Mentoring merupakan kegiatan unggulan PAS-ITB yang mana kegiatan tersebut berbentuk pembinaan yang dilakukan bersama adik-adik usia TK dan juga SD.",
			},
			{
				title: "Pesantren Kilat",
				desc: "Pesantren Kilat merupakan kegiatan yang tepat untuk mengisi liburan dengan sebaik-baiknya. Melalui pesantren kilat, anak akan menerima nilai-nilai keagamaan sebagai dasar pembentukan kepribadian dan moral yang akan tertanam  dalam jiwa dan sanubari. Dalam pesantren kilat ini akan dipelajari aspek-aspek kebersamaan, kepemimpinan, dan kemasyarakatan, baik secara langsung maupun tidak langsung.",
			},
			{
				title: "Embun Pagi",
				desc: "Embun Pagi merupakan salah satu program kerja yang dilaksanakan pada hari ahad pagi. Kegiatannya diisi dengan pematerian yang disampaikan oleh setiap divisi dengan silabus yang telah dibuat, dilanjut dengan sesi tanya jawab dan diskusi.",
			},
		],
		gallery: [
			{
				img: "1.jpeg",
			},
			{
				img: "2.jpeg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.HEIC",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Siffa Noorjanah",
		pembina: "Beragama Islam, Memiliki ketertarikan terhadap dunia anak-anak, Mahasiswa maksimal semester 4 untuk jenjang S1 atau semester 2 untuk jenjang D3, Berstatus belum menikah, Tidak terlibat dalam organisasi terlarang yang ditetapkan MUI, Sudah mengikuti SSC atau berkomitmen mengikuti SSC terdekat setelah mendaftar PKB, Berada di wilayah Bandung Raya",
	},
	{
		id: "5",
		title: "PUSTENA",
		slug: "pustena",
		logo: "pustena.png",
		video: "https://www.youtube.com/embed/CTaeTgBiDK8",
		profile:
			"Berlandaskan filosofi dasar Masjid Salman ITB yang merupakan laboratorium ruhani dan kampus ITB sebagai pusat pengkajian ilmu pengetahuan, teknologi, dan seni. Diharapkan Masjid Salman ITB mampu melahirkan kaum intelektual muslim sejati yang mempunyai keseimbangan iman, ilmu dan amal. Sudah menjadi tantangan kaum intelektual muslim, khususnya mahasiswa muslim ITB untuk mengembangkan dan mengamalkan profesi di bidang ilmu dan teknologi yang menjadi beban amanahnya. Oleh karena itu, dibentuklah Pusat Teknologi Tepat Guna (PUSTENA) sebagai suatu wadah untuk mengkaji dan menerapkan teknologi untuk diterapkan di Masjid Salman ITB dan masyarakat luas sehingga mampu menjadi insan rahmatan lil’alamin. PUSTENA berdiri pada 12 Januari 1986.",
		vision:
			"Menjadikan Masjid Salman sebagai laboratorium ruhani dan teknologi, serta menghasilkan inovasi yang rahmatanlilalamin",
		mission: "Memberikan wadah keprofesian dalam bidang teknologi, Menghasilkan Kader produktif dalam bidang teknologi, Berkontribusi dalam pengembangan teknologi masjid kampus",
		instagram: "https://www.instagram.com/pustenasalmanitb/",
		activities: [
			{
				title: "Ristek (Riset Teknologi)",
				desc: "Bayangin kita lagi investigasi seru! Tim kita bakal ngulik teknologi keren yang bisa langsung dipakai sehari-hari. Kita bakal ngecek, ngulik, terus ngasih tahu hasilnya ke semua orang. Jadi, kalau ada inovasi baru, kita yang pertama kasih info!",
			},
			{
				title: "Ngojek (Ngoprek Projek)",
				desc: "Ini waktu seru buat ngoprek! Tiap tim bakal rutin kumpul buat bongkar-pasang, ngecek, dan ngotak-atik proyek mereka. Mulai dari riset, survey, bikin ulang prototipe, sampai upgrade hardware dan software—pokoknya bebas ngeksplorasi ide!",
			},
			{
				title: "Perinov",
				desc: "Punya ide cemerlang tapi butuh dukungan? Di sini tempatnya! Kita bakal bantu kamu yang punya inovasi, baik dalam bentuk dana, pelatihan, atau sumber daya lain. Intinya, kita bantu ide kamu jadi kenyataan, terutama kalau buat lomba atau pengembangan produk.",
			},
			{
				title: "The PIT (Pustena Integrated Training)",
				desc: "Sesi latihan komplit buat kru kita! Fokusnya di engineering dan riset teknologi yang bisa langsung dipakai. Jadi, siap-siap belajar hal seru yang beneran berguna buat proyek kita!",
			},
			{
				title: "Kunjungan Industri",
				desc: "Waktunya jalan-jalan ke perusahaan industri! Kita bakal tur keliling, ngobrol langsung sama pakar, dan lihat demo produk keren. Ini kesempatan buat dapet inspirasi baru dan nambah jaringan profesional buat masa depan kita.",
			},
			{
				title: "Grand Project (Mosque Integrated System)",
				desc: "Menjadikan Masjid Salman ITB menjadi Masjid yang terintegrasi dalam IT dengan menggunakan sebuah aplikasi untuk membantu kinerja masyarakat Masjid Salman ITB.",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Nabil Karim",
		pembina: "Mahasiswa/i S1/D3/D4 muslim diutamakan kampus sekitar bandung raya, Memiliki antusiasme tinggi pada pengembangan teknologi tepat guna, Pernah mengikuti SSC (Salman Spiritual Camp) atau bersedia mengikutinya dalam waktu dekat, Berkomitmen untuk aktif dalam mengoprek atau mengulik teknologi baru",
	},
	{
		id: "6",
		title: "REKLAMASA",
		slug: "reklamasa",
		logo: "reklamasa.png",
		video: "https://www.youtube.com/embed/JjQkspZ3E8s",
		profile:
			"Berawal dari kegiatan rutin yang bersifat kreatif yang diadakan oleh beberapa mahasiswa ITB dalam rangka mengisi Ramadhan di Masjid Salman ITB kemudian berlanjut hingga menjadi komunitas kreatif yang bergerak dalam medan Syiar Islam. Untuk memperteguh posisinya di Masjid Salman, akhirnya dibentuklah unit kreatif Reklamasa ITB (Reklame Kreasi Masa Salman ITB). Reklamasa secara resmi berdiri pada 19 Juni 2016 (Ramadhan 1437 H). Sekarang, Reklamasa berfokus kepada syiar dakwah kreatif khususnya di bidang videografi.",
		vision:
			"Reklamasa sebagai rumah dan inkubator jiwa bermain untuk akselerasi pribadi kreatif dan spritualitatif",
		instagram: "https://www.instagram.com/reklamasaitb/",
		activities: [
			{
				title: "Rekla Academy",
				desc: "Rangkaian pembinaan epik nan ciamik untuk Reklamuda agar jiwa dan skillnya terasah untuk membuat konten video yang mantap",
			},
			{
				title: "AsikBener!",
				desc: "Program pembinaan dalam bentuk ngobrol keislaman dan kekreatifan untuk anggota Reklamasa yang kemudian dipublikasikan dalam bentuk podcast untuk khalayak ramai di Spotify dan Anchor (awas ae lu blom danger!!)",
			},
			{
				title: "Video Series",
				desc: "Konten video syiar yang memiliki rangkaian tema dalam suatu periode dengan satu label video yang tetap. Periode dari video ini jangka pendek, bisa ada dan tidak sewaktu-waktu.",
			},
			{
				title: "Video Nonseries",
				desc: "Konten video rutin yang tidak memiliki rangkaian tema baku. Sifatnya insidental, bisa berupa dokumentasi vlog kegiatan Reklamasa atau konten hiburan dengan satu topik yang tidak berkelanjutan.",
			},
			{
				title: "Dan kegiatan semau kita lainnya yang InsyaAllah asik dan keren",
				desc: "RekRamadhan, Riyaan, dll",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Agung PW",
		pembina: "Bisa bercanda, ngerti berkesenian dalam bentuk apapun, luwes dan terbuka pemilikirannya, sabar, gak baperan, kalo ada yang pantun reflek bales cakepp, spontan uhuyy",
	},
	{
		id: "7",
		title: "Salman Entrepreneur Club",
		slug: "sec",
		logo: "sec.png",
		video: "https://www.youtube.com/embed/l9W_-jCT-4w",
		profile:
			"Salman Entrepreneur Club (SEC) yang berdiri pada 19 Januari 2014 merupakan pengembangan dari komuitas bisnis yang dibentuk oleh beberapa orang penerima beasiswa yang kemudian berinisiatif untuk membentuk suatu komunitas yang berbasis di lingkungan masjid Salman ITB. SEC menjadi wadah bagi mahasiswa yang ingin belajar tentang kewirausahaan, dimulai dari pemula hingga yang sudah berbisnis. SEC memiliki misi dalam pembinaan terhadap sekitar dengan harapan yang SEC bina dapat berwirausaha serta menumbuhkan ekonomi di Indonesia.",
		vision:
			"Mengembangkan jiwa kewirausahaan",
		instagram: "https://www.instagram.com/sec.salmanitb/",
		activities: [
			{
				title: "Seminar Akbar SEC",
				desc: "Event seminar tahunan sebagai bentuk syiar wirausaha dan kaderisasi. Kegiatan ini merupakan kegiatan tahunan SEC yang merupakan tugas akhir dari program kerja mentoring bisnis sekaligus sebagai syiar wirausaha dan kaderisasi SEC.",
			},
			{
				title: "Video Series",
				desc: "Konten video syiar yang memiliki rangkaian tema dalam suatu periode dengan satu label video yang tetap. Periode dari video ini jangka pendek, bisa ada dan tidak sewaktu-waktu.",
			},
			{
				title: "Mentoring Bisnis",
				desc: "Memberikan training bisnis dengan pola pembelajaran berbentuk mentoring. Kegiatan ini dilatarbelakangi salah satu kebutuhan pengusaha yang paling penting selain komunitas, buku, dan pelatihan adalah mentoring. Mentoring mewadahi para mentee untuk berbagi pengalaman dan wawasan serta memberikan berbagai alternatif solusi atas permasalahan yang umumnya terjadi di kalangan pengusaha.",
			},
			{
				title: "Pelatihan Bisnis",
				desc: "Tuntutan zaman yang serba cepat dan terus berkembangnya usaha membuat para pengusaha harus terus meningkatkan kapasitas dirinya, baik dari segi soft skills dan hard skills. Pelatihan bisnis dibuat untuk memberikan training bisnis dengan pelatihan oleh para ahli.",
			},
			{
				title: "SEC Silaturahim",
				desc: "Kopdar SEC ini dilatarbelakangi oleh sedikit renggangnya hubungan antara alumni dan anggota baru SEC. Menjaga silaturahim antar anggota SEC dan Alumni.",
			},
			{
				title: "SEC Sosial",
				desc: "Kegiatan ini dilatarbelakangi oleh banyaknya pengusaha yang kurang peka terhadap kehidupan sosial di sekitarnya..",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Bobi Nur Mulyana ",
		pembina: "Memahami bisnis, marketing, manajemen",
	},
	{
		id: "9",
		title: "GAMAIS ITB",
		slug: "gamais",
		logo: "gamais.png",
		video: "https://www.youtube.com/embed/OmH-QX4YJFQ",
		profile:
			"Keluarga Mahasiswa Islam Institut Teknologi Bandung (GAMAIS ITB) merupakan organisasi lembaga dakwah kampus (LDK) di lingkup kampus Institut Teknologi Bandung. Berdiri pada 30 Agustus 1987, organisasi ini menaungi seluruh aktivitas dakwah Islam mahasiswa di dalam kampus, terutama aktivitas dakwah di program studi dan fakultas. GAMAIS ITB memiliki sekretariat yang berlokasi di kompleks Masjid Salman ITB, yaitu  masjid kampus yang terletak di Lebak Siliwangi, Coblong, Kota Bandung, atau tepat di depan area Kampus ITB Ganesha.",
		vision:
			"GAMAIS Sebagai Rumah yang Ramah dan Inspiratif",
		mission: "Membangun dan menguatkan profil, nilai, dan budaya keislaman yang komprehensif guna menghasilkan anggota GAMAIS yang loyal dan diharapakan menjadi teladan massa kampus, Berkolaborasi dengan pihak-pihak strategis sebagai sebuah terobosan dalam pengembangan GAMAIS ITB, Menciptakan sistem pemenuhan kebutuhan dan manajemen sumber daya yang adaptif dengan wadah pengembangan diri yang relevan, Mengembangkan dakwah fardiyah melalui peningkatan eksistensi kebermanfaatan GAMAIS WIlayah, Menjadi wadah massa kampus dalam pengembangan intelektual keislaman yang ramah dan bersahabat, Membangun keterikatan dan kehangatan yang menyeluruh dalam ruang kekeluargaan yang inklusif",
		instagram: "https://www.instagram.com/gamaisitb/",
		activities: [
			{
				title: "Mentoring",
				desc: "Pembinaan untuk kader GAMAIS yang bersifat relevan dan mampu menjawab kebutuhan",
			},
			{
				title: "SIMFONI",
				desc: "Kaderisasi awal GAMAIS ITB",
			},
			{
				title: "Ramadhan On Kampus",
				desc: "Kegiatan memeriahkan suasana pada bulan Ramadhan didalam kampus. Seperti Irama On Campus, berbagi makanan buka puasa, dan buka puasa bersama di dalam kampus",
			},
			{
				title: "Asrama GAMAIS",
				desc: "Mendirikan asrama yang bersifat pembinaan kepada massa GAMAIS. Namun diprioritaskan untuk anggota GAMAIS yang baru saja lulus TPB",
			},
			{
				title: "GAMAIS Bonding",
				desc: "Melaksanakan kegiatan-kegiatan internal yang menyenangkan untuk massa GAMAIS dengan tujuan mencetak anggota yang loyal",
			},
			{
				title: "GAMAIS Mengaji",
				desc: "Menginisiasi acara kajian yag sifatnya terbuka untuk massa kampus",
			},
			{
				title: "Ekspedisi Tanah Air 4.0",
				desc: "Program pengabdian masyarakat ke daerah 3T",
			},
			{
				title: "GAMAIS Mengabdi",
				desc: "Program pengabdian masyarakat ke daerah sekitar kampus ITB",
			},
			{
				title: "PMB GAMAIS",
				desc: "Program penerimaan mahasiswa baru di OSKM atau kegiatan PMB kampus lainnya. Program penerimaan anggota GAMAIS Wilayah yang baru",
			},
			{
				title: "G-Will Action",
				desc: "Seluruh GAMAIS WIlayah melaksanakan kegiatan secara mandiri yang menjadi ciri khas dari fakultasnya masing-masing",
			},
			{
				title: "Rumah Cantik Annisaa",
				desc: "Melaksanakan event kemuslimahan dengan tujuan dakwah kepada kaum perempuan di ITB",
			},
			{
				title: "Annisaa Needs",
				desc: "Program pemenuhan kebutuhan dasar mahasiswi ITB",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.png",
			},
			{
				img: "6.jpg",
			},
			{
				img: "7.jpg",
			},
			{
				img: "8.jpg",
			},
			{
				img: "9.jpeg",
			},
		],
		ketua: "Fitrah Nur Muliadin Harahapa",
		pembina: "Paham mengenai pergerakan Dakwah Kampus",
	},
	{
		id: "10",
		title: "KAMIL Pascasarjana ITB",
		slug: "kamil",
		logo: "kamil.png",
		video: "https://youtu.be/ryHncDPd040?si=s9yGdmJ9hfusw_SQ",
		profile:
			"Keluarga Mahasiswa Islam Pascasarjana Institut Teknologi Bandung didirikan pada tanggal 2 Syaban 1412 H/22 November 1998 di Bandung dengan nama Ikatan Mahasiswa Muslim Pascasarjana (IMMPAS) ITB. Organisasi ini berubah nama menjadi Himpunan Mahasiswa Muslim Pascasarjana (HIMMPAS) ITB pada 7 Maret 2010 di Pusdiklat Geologi Bandung. Organisasi ini kemudian disahkan sebagai unit pascasarjana ITB pada 10 Rabiul Awal 1433 H/3 Februari 2012 dengan nama Keluarga Mahasiswa Islam (KAMIL) Pascasarjana ITB.",
		vision: " Keluarga Mahasiswa Islam Pascasarjana Institut Teknologi Bandung (Kamil Pascasarjana ITB) merupakan Lembaga Dakwah Kampus (LDK) Pascasarjana ITB yang bertujuan untuk mengaktualisasikan nilai-nilai Islam sekaligus sebagai wadah silaturahim dan wadah kajian di lingkungan civitas akademika pascasarjana ITB dan masyarakat. Unit ini juga menjadi wadah studi ilmu pengetahuan yang akan mengokohkan kompetensi civitas akademika ITB.",
		instagram: "https://www.instagram.com/kamilpascaitb/",
		activities: [
			{
				title: "Rumah APS",
				desc: "Kegiatan pembinaan terkait aspek sosial, akademik, spiritual, dan profesi yang dilaksakan setiap pekan maupun eventual oleh mentor APS yang telah ditentukan oleh PPSDM KAMIL. Kegiatan eventual berupa APS Gabungan, SIP yang bekerjasama dengan Akpro dan APSG akhwat",
			},
			{
				title: "Insan Kamil",
				desc: "PPAB Kamil Pascasarjana (Proses Penerimaan Anggota Baru) adalah sebuah rangkaian kegiatan yang terdiri dari open recruitment dan kaderisasi Kamil Pascasarjana. Agenda kegiatan didalam nya adalah Open Recruitment, Kamil Gathering, Kamil Trip dan Upgrading seluruh anggota Kamil",
			},
			{
				title: "Sekolah Ilmiah Pascasarjana",
				desc: "Seminar terkait karya tulis ilmiah (KTI) dan kekayaan intelektual (KI) untuk memberi pemahaman terkait teori dan praktek yang berhubungan dengan karya tulis ilmiah (KTI) dan kekayaan intelektual (KI) pada mahasiswa pascasarjana ITB",
			},
			{
				title: "Kompas Karir",
				desc: "Kegiatan mentoring dan pembinaan terkait keprofesian untuk memberikan pengarahan dan pembinaan terkait keprofesian di beberapa bidang seperti, dosen, peneliti maupun industri kepada anggota KAMIL Pascasarjana ITB",
			},
			{
				title: "Rumah Al Quran Pascasarjana",
				desc: "Asrama dan kelas pembinaan Al Quran untuk mahasiswa/i pascasarjana ITB",
			},
			{
				title: "Institut Ramadhan Terbaik",
				desc: "Rangkaian kegiatan Ramadhan dimulai dari tarhib, persiapan, mabit dan itikaf bersama, workshop bacaan Al Quran, Sholat, dan Zakat Fitrah, serta open house idul fitri",
			},
			{
				title: "Kamil Mengajar",
				desc: "Kegiatan pengabdian dalam bidang pengajaran yang difasilitasi oleh Kamil untuk seluruh mahasiswa pascasarjana ITB. Lokasi pengajaran: Rita Home Library dan Pesantren Mitra Muslim",
			},
			{
				title: "Kamil Learning Community",
				desc: "Kegiatan kajian tematik berkelanjutan dengan tema non-insidental. Pada periode kepengurusan ini, tema yang diangkat adalah urgensi dan implementasi riset akademis untuk Indonesia emas 2045",
			},
			{
				title: "Cakrawala",
				desc: "Syiar materi keislaman khusus Muslimah (fiqh wanita; isu aktual) dalam bentuk kajian dan/atau talkshow",
			},
			{
				title: "Muslimah Academy",
				desc: "Kegiatan pelatihan khusus Muslimah sebagai upaya peningkatan kapasitas dan keterampilan Muslimah untuk meningkatkan kapasitas dan keterampilan muslimah selaku calon ibu peradaban",
			},
			{
				title: "Adiwidya Pascasarjana",
				desc: "Pameran dan orasi ilmiah dari mahasiswa pascasarjana ITB untuk memperkenalkan penelitian yang sedang dilakukan. Kegiatan ini juga dilengkapi dengan mock conference dan lomba-lomba ilmiah lainnya",
			},
			{
				title: "Kampung Madani",
				desc: "Program pengabdian Kamil untuk membangun sebuah desa melalui riset dan kegiatan sosial islami sebagai desa model peradaban",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Targhib Ibrahim, S.T.",
		pembina: "Memiliki dedikasi tinggi dan pemahaman mengenai dakwah pascasarjana",
	},
	{
		id: "11",
		title: "P3RI",
		slug: "p3ri",
		logo: "p3ri.png",
		video: "https://www.youtube.com/embed/MVWYf3UPMvs",
		profile:
			"Panitia Pelaksana Program Ramadhan dan Idul Adha (P3RI) Masjid Salman ITB merupakan panitia tahunan yang ditunjuk secara resmi oleh YPM Salman ITB untuk menyelenggarakan pelayanan terkhusus pada Bulan Ramadhan dan Hari Raya Idul Adha. Pelayanan yang dimaksud berupa program-program yang memeriahkan Ramadhan dan Adha serta memicu kebaikan dan kebermanfaatan bagi masyarakat. Di tengah pandemi COVID-19, P3RI akan tetap berinovasi dan beradaptasi agar semarak Ramadhan dan Adha tetap dapat dirasakan masyarakat meskipun sebagian besar program dilaksanakan secara online",
		vision:
			"Mimpi P3RI 1445 H Menjadi Kepanitiaan Ramadhan dan Idul Adha yang Merdeka Melayani",
		mission:
			"Melayani Jamaah Masjid Salman dengan Sepenuh Hati, Mengaktualisasikan Kegiatan Ramadhan dan Adha yang Penuh Impresi, Membentuk Kepanitiaan P3RI yang Berkualitas dan Berdedikasi, Melaksanakan Komitmen Kepanitiaan Secara Terintegrasi, Menciptakan Lingkungan P3RI yang Saling Memahami",
		instagram: "https://www.instagram.com/p3ri.salmanitb/",
		activities: [
			{
				title: "Inspirasi Ramadhan (IRAMA)",
				desc: "Talkshow online inspiratif, menghadirkan berbagai tokoh ternama",
			},
			{
				title: "Berbagi Buka & Sahur",
				desc: "Pembagian makanan dan minuman berbuka dan sahur di sekitar Masjid Salman ITb dan beberapa jalan di Bandung Raya menggunakan protokol kesehatan",
			},
			{
				title: "Para Pejuang Alquran (PPA)",
				desc: "Serangkaian kegiatan bertujuan untuk meningkatkan semangat mentadabburi Al-Quran secara online dan berskala nasional",
			},
			{
				title: "Bakti Sosial",
				desc: "Kegiatan bakti sosial untuk masyarakat di sekitar Bandung Raya yang dilakukan di bulan Ramadhan",
			},
			{
				title: "Festival Ramadhan",
				desc: "Kegiatan lomba dan challenge nasional (Essay, MTQ, Pildacil, talkshow dengan pembicara menarik",
			},
			{
				title: "Tarhib (Kajian Pembuka Ramadhan)",
				desc: "Talkshow atau bincang pra-Ramadhan yang mengundang pembicara ahli di bidangnya dengan tujuan menciptakan atmosfer kemeriahan Ramadhan",
			},
			{
				title: "Program IdulAdha",
				desc: "Menjadi panitia kurban di Masjid Salman ITB, pada 1445 H terhitung ada 52 Kambing yang dapat disembelih oleh panitia kurban. Selain itu, digelarnya acara festival adha dengan acara utama bagi-bagi 10.000 Sate Gratis menambah keseruan dan semaraknya Adha di Salman ITB",
			},
			{
				title: "Program di luar Hari Raya",
				desc: "Panitia akan mendapatkan pengalaman luar biasa baik dari segi manajerial sumber daya manusia, mencari dana mendatangi korporat-korporat besar, dan juga mengasah skill editing dan grafis. P3RI juga menyediakan wadah untuk panitianya berkembang di segala lini dengan program volunteer yakni program saling membantu antar divisi dengan divisi lainnya. Dengan potensi sebesar ini, tentu relasi akan sangat mudah didapatkan lewat P3RI"
			}
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
			{
				img: "7.jpg",
			},
			{
				img: "8.jpg",
			},
			{
				img: "9.jpg",
			},
		],
		ketua: "Muhammad Luthfi Aunillah",
		pembina: "Hamba Allah yang Siap menjadi Pelayanan Ummat",
	},
	{
		id: "12",
		title: "MTQ ITB",
		slug: "mtq",
		logo: "mtq.jpg",
		video: "",
		profile:
			"Unit yang bergerak di bidang Seni Musabaqah Tilawatil Qur'an yang bertujuan untuk menumbuhkan jiwa Qurani pada setiap anggotanya dan menyalurkan minat dan bakat mereka melalui berbagai ajang lomba yang dapat diikuti serta ditujukan kepada mahasiswa ITB.",
		vision:
			"Menjadi wadah pengembangan potensi dan prestasi mahasiswa ITB di bidan Musabaqah Tilawatil Quran",
		mission:
			"Menghimpun dan menciptakan wadah bagi mahasiswa ITB untuk dapat mengembangkan diri dalam rangka persiapan lomba MTQ, Memfasilitasi pembinaan ruhiyah kepada pengurus dan anggota secara rutin, Menyelenggarakan kegiatan internal antar pengurus dan anggota secara berkala, Menyeleksi kontingen untuk mengikuti ajang perlombaan MTQ, Berkoordinasi dengan BMKA Masjid Salman ITB dalam berbagai kegiatan, Menjadi cerminan role model UKM Al Qur’an di Indonesia, Mengadakan kegiatan yang dapat memberikan kebermanfaatan kepada masyarakat",
		instagram: "https://www.instagram.com/mtq_salmanitb/",
		activities: [
			{
				title: "Pembinaan",
				desc: "Pembinaan secara rutin untuk masing-masing cabang lomba",
			},
			{
				title: "Hunting Lomba",
				desc: "Mencari informasi dan aktif mengikuti berbagai ajang lomba yang berkaitan dengan MTQ",
			},
			{
				title: "Kafilah MTQMN",
				desc: "Anggota yang aktif mengikuti pembinaan dan lolos seleksi internal MTQ ITB akan berkesempatan untuk menjadi bagian dari kontingen MTQ ITB yang akan didelegasikan untuk berpartisipasi dalam ajang MTQMN",
			},
			{
				title: "Bonding Skuy",
				desc: "Kegiatan internalisasi antar pengurus dan anggota secara berkala untuk memperkuat rasa kekeluargaan",
			},
			{
				title: "MTQ untuk Umat",
				desc: "Terjun ke masyarakat dengan memberikan pengajaran ilmu Al Qur'an serta aktif membagikan konten-konten Islami yang berisi wawasan seputar Al Qur'an",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "1.jpg",
			},
			{
				img: "1.jpg",
			},
		],
		ketua: "Malik Syddiq Arrasyid",
		pembina: "Mahasiswa/i Institut Teknologi Bandung, Memiliki semangat dan daya juang yang tinggi untuk mempelajari seni dan ilmu Al Qur'an, Berkomitmen untuk mengikuti seluruh agenda pembinaan yang diberikan",
	},
	{
		id: "13",
		title: "LIKESA (Lingkar Kreatif Salman)",
		slug: "likesa",
		logo: "likesa.png",
		video: "",
		profile:
			"Likesa (Lingkar Kreatif Salman) merupakan unit yang bergerak di bidang craft atau kerajinan tangan.",
		vision:
			"Menjadi wadah bagi generasi muslim mengembangkan kreatifitas yang berorientasi pada karya yang bernilai dan berguna.",
		mission:
			"Membangun lingkungan hangat dan nyaman untuk kreatifitas, Memberikan pembinaan sebagai upaya meningkatkan kreatifitas serta memperluas potensi diri, Menampung setiap ide kreatif, Mewujudkan ide tersebut dalam bentuk karya.",
		instagram: "https://www.instagram.com/likesa_salmanitb/",
		activities: [
			{
				title: "Workshop Internal",
				desc: "Pengurus Likesa diajarkan 3 jenis workshop dengan beberapa pertemuan di setiap workshopnya. Workshop periode 2023-2024 diantaranya: workshop rajut (membuat mirror tag, buket bunga tulip), workshop makrame (membuat gantungan kunci, lanyard, bottle holder), workshop watercolor (melukis basic landscape, food watercolor)",
			},
			{
				title: "Workshop Umum",
				desc: "Pengurus Likesa mengajarkan serta mendampingi peserta umum. Salah satunya kegiatan akhir tahun salman yaitu Workshop Buket Bunga: Spesial Hari Ibu",
			},
			{
				title: "Berkabar",
				desc: "Kegiatan temu sesama anggota Likesa sebagai bentuk silahturahmi dan bonding, kegiatannya antara lain membahas satu tema tertentu, hiking atau jalan-jalan dan eksplorasi jenis seni lain di luar workshop",
			},
			{
				title: "Pameran",
				desc: "Wadah untuk memamerkan hasil karya yang dibuat selama satu periode dalam rangka tanggung jawab unit dan bentuk apresiasi diri",
			},
		],
		gallery: [
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
			{
				img: "7.jpg",
			},
			{
				img: "8.jpg",
			},
			{
				img: "9.jpg",
			},
			{
				img: "10.jpg",
			},
		],
		ketua: "Roihanah",
		pembina: "Memiliki minat dan pemahaman mengenai craft atau kerajinan tangan serta dapat menggali potensi anggota likesa hingga memaksimalkannya menjadi suatu hasil karya yang bermanfaat",
	},
	{
		id: "14",
		title: "Salman Archery Center (SAC)",
		slug: "sac",
		logo: "sac.png",
		video: "https://www.instagram.com/reel/C7TP_n6BR2H/?utm_source=ig_web_copy_link",
		profile:
			"Unit olahraga di Masjid Salman ITB yang menekuni salah satu permainan dalam Islam yaitu memanah atau panahan tradisional. Unit ini berupaya memberikan pengalaman, pembentukan kebiasaan, dan pelatihan kepada anggota unit dan jama'ah Salman.",
		vision:
			"Mendakwahkan sunnah panahan",
		instagram: "https://www.instagram.com/sac.salmanitb/",
		activities: [
			{
				title: "Weekly Exercise: Latihan Rutin",
				desc: "Latihan panahan anggota mandiri tiap pekan dengan asisten pelatih",
			},
			{
				title: "Weekly Coaching: Latihan Bersama Pelatih",
				desc: "Latihan panahan anggota bersama pelatih utama tiap akhir pekan ",
			},
			{
				title: "SAC-GC (Goes to Competition)",
				desc: "Mengirim anggota mengikuti Kejuaraan Panahan Minimal 2 kali dalam 1 kepengurusan",
			},
			{
				title: "DIY (Do It Yourself)",
				desc: "Membuat serta Menjual Busur dan Anak Panah Minimal 5 busur dan 50 anak panah dalam 1 kepengurusan",
			},
			{
				title: "(BAQ) Become Archer Quickly",
				desc: "Memberi pinjaman dana tanpa bunga kepada anggota untuk membeli peralatan memanah sendiri. Pengembalian dengan mencicil sesuai kesepakatan di awal",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.png",
			},
			{
				img: "3.jpeg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Farhatan Fajri Sobliaa",
		pembina: "Punya interest di panahan, ahli panahan standar dan tradisional, luas keilmuan panahan, pengalaman, luas relasi.",
	},
	{
		id: "15",
		title: "Naungan Musik Islami Salman (NASA)",
		slug: "nasa",
		logo: "nasa.jpeg",
		video: "https://drive.google.com/file/d/1a-ahR-IljkJ9pKwZJAvBdYQinrcTNI1v/view?usp=drive_link",
		profile:
			"NASA merupakan unit yang bergerak di bidang karya musik islami. NASA merupakan singkatan dari 'Namil Salman'. Namil adalah kepanjangan dari 'Naungan Musik Islami', maka kepanjangan utuh NASA adalah 'Naungan Musik Islami Salman’. Namil diambil dari nama salah satu surat dalam Al-Qur'an yaitu An-Naml yang artinya semut. Semut dikenal memiliki nilai ukhuwah yang sangat tinggi antar sesamanya. Sedangkan pemilihan kata 'Naungan' memiliki makna dan harapan agar NASA dapat menjadi naungan yang memberikan rasa aman dan nyaman.",
		vision:
			"Mewujudkan NASA  sebagai rumah kedamaian untuk media dakwah menuju ukhuwah islamiyah menggapai berkah menjalankan kebaikan dalam bentuk pergerakan musik sebagai dan Ridho Allah SWT",
		mission: "Menciptakan wadah yang bisa memaksimalkan tali persaudaraan sesama muslim di lingkungan masjid salman dalam beraktivitas dan berdakwah, Membentuk kader-kader aktivis unit yg mampu mengelola dan memberdayakan satu sama lainya dengan potensi yang dimiliki  masing-masing khususnya di ranah musik bernuansa islami, Membentuk kebiasaan dalam hal berkarya sebagai media dan  sarana kita berdakwah dengan tetap memegang nilai-nilai  keislaman sebagai pedoman unit",
		instagram: "https://www.instagram.com/nasa.namilsalman/",
		activities: [
			{
				title: "Produksi Karya Lagu",
				desc: "Membuat komposisi musik serta aransemen musik, untuk kemudian dibuat menjadi 1 rekaman karya musik yang nantinya akan dipublikasikan kepada masyarakat umum",
			},
			{
				title: "Coaching Clinic",
				desc: "Kegiatan coaching musik, berupa vokal dan alat musik gitar dan atau piano sebagai wawasan dan ide baru dalam membuat 1 buah karya musik dan memainkan alat musik atau teknik bernyanyi yang benar",
			},
			{
				title: "ORKESTRA (Open Recruitment Anggota Baru NASA)",
				desc: "Open recruitment bagi aktivis salman dan atau khalayak umum yang berminat dalam pengembangan music Islami dan atau memiliki bakat dalam bermusik",
			},
			{
				title: "MARINASA (Makrab with NASA)",
				desc: "Kegiatan Camp bersama anggota dan pengurus NASA dalam rangka mempererat tali persaudaraan antara satu sama lain dan membangun sebuah chemistry dan komunikasi yang baik",
			},
			{
				title: "Music Festival",
				desc: "Kegiatan tahunan yang diadakan oleh NASA bagi Masyarakat umum yang memiliki minat dan bakat dalam bermusik/bernyanyi Nasyid yang diadakan untuk Masyarakat umum dari kalangan sekolah hingga menengah atas se-Bandung Raya",
			},
			{
				title: "Resital Vokal",
				desc: "Ajang penampilan para anggota dan pengurus NASA dalam rangka mengimplementasikan hasil proses Latihan vocal/music selama 1 tahun di NASA",
			},
			{
				title: "Latihan Rutin",
				desc: "Agenda Latihan rutinan setiap 1 bulan 1-2 kali. Kegiatan ini merupakan Latihan vocal, manajemen suara, nafas dan artikulasi suara dan pengembangan dalam bermain music gitar",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Muhamad Ihsan",
		pembina: "Alumni Keanggotaan Unit Namil Salman, Memiliki Passion di bidang musik, Alumni Aktivis Salman ITB",
	},
	{
		id: "17",
		title: "YIM (Young Intellectual Moslem)",
		slug: "yim",
		logo: "yim.png",
		video: "",
		profile:
			"Young Intellectual Moslem (YIM) adalah wadah pengembangan aktivis Salman ITB untuk mengkaji ideologi serta pemikiran dalam perspektif intelektual islam dengan tujuan Memberikan kontribusi untuk kemajuan peradaban islam melalui pembinaan dan pelatihan yang berkaitan dengan ideologi dan pemikiran guna Melindungi mahasiswa dari berbagai pengaruh ideologi yang tidak sesuai dengan ajaran islam",
		vision:
			"Menjadi unit pengkajian ragam ideologi dari perspektif islam yang berkontribusi pada peningkatan tradisi keilmuan mahasiswa muda muslim guna melindungi mahasiswa dari paham yang tidak sesuai dengan ajaran islam",
		mission:
			"Menanamkan worldview islam dengan cara melatih dan membina seluruh anggota unit Young Intellectual Moslem, Mengembalikan memori sejarah peradaban islam melalui berbagai pengkajian dan studi literatur, Mendorong seluruh anggota Young Intellectual Moslem dalam Merancang RKU (Rencana Kontribusi Unggulan) sebagai andil jangka panjang serta berkelanjutan terhadap peningkatan tradisi keilmuan mahasiswa muda muslim",
		instagram: "https://www.instagram.com/youngintellectualmoslem/",
		activities: [
			{
				title: "Kelas Peminatan",
				desc: "Kelas peminatan bertujuan untuk memberikan pembekalan keterampilan kepenulisan dan konten kreator. kelas peminatan terbagi menjadi dua kategori, yakni kelas kepenulisan dan kelas konten kreator. Setiap peserta bisa mengakses kedua kelas tersebut, dan kedepannya diwajibkan untuk mengerjakan output",
			},
			{
				title: "Kelas Pemikiran dan Ideologi",
				desc: "Bertujuan untuk mengembangkan tradisi keilmuan di kalangan pemuda muslim, penanaman worldview islam dan pengenalan problematika yang mendera pemikiran dan peradaban islam kini. Kegiatan diselenggarakan secara online dan offline  berisi pemaparan materi dan diskusi isu isu terkait pemikiran dan ideologi dalam perspektif keilmuan islam.",
			},
			{
				title: "Kelas Sejarah Islam",
				desc: "Bertujuan Mengembalikan memori sejarah peradaban islam dengan mendalami pola sejarah kebangkitan/kemunduran islam. Kegiatan diselenggarakan secara online berisi pemaparan materi dan diskusi isu isu terkait sejarah peradaban islam.",
			},
			{
				title: "Bimbingan Rancangan Kontribusi Unggulan",
				desc: "Terdapat pembinaan anggota untuk siap memaksimalkan peran dan berkontribusi untuk peradaban. Kegiatan diselenggarakan secara online, berisi pemaparan materi mengenai pengembangan kapasitas dan mindset juga pengarahan life planning dan karir yang sesuai dengan visi islam dan berdampak bagi peradaban.",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpeg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
			{
				img: "6.jpg",
			},
		],
		ketua: "Rita Lestari",
		pembina: "Mahasiswa / mahasiswi muslim, Memiliki keinginan untuk belajar dan bertumbuh Bersama, Menyukai atau tertarik mempelajari isu pemikiran dan peradaban islam, Berkomitmen untuk mengikuti seluruh agenda, bersedia mengikuti SSC",
	},
	{
		id: "18",
		title: "PRAMA",
		slug: "prama",
		logo: "prama.png",
		video: "",
		profile:
			"Unit kepanitiaan Ramadhan dan Adha di Masjid Al-Jabbar ITB Jatinangor yang merupakan kepanitiaan di bawah DKM Al-Jabbar ITB Jatinangor dan merupakan unit mitra Salman ITB dalam penyelenggaraan urusan pelayanan jamaah di kegiatan Ramadhan, Idulfitri, dan Iduladha di lingkungan Kampus ITB Jatinangor",
		vision:
			"Mewujudkan sistem dan lingkungan Ramadhan dan Adha Masjid Al-Jabbar ITB Jatinangor menuju ketakwaan dengan merestorasi semangat kebaikan secara berkelanjutan",
		mission:
			"Menghidupkan suasana Ramadhan dan Iduladha dengan menggiatkan kegiatan Islami agar dapat menumbuhkan kesadaran terhadap masjid sebagai tempat terbaik mengembangkan diri. Memberikan layanan terbaik bagi jamaah Masjid Al-Jabbar ITB Jatinangor guna mencapai tujuan ketakwaan agar semangat Ramadhan tetap ada secara berkelanjutan. Mengembangkan ekosistem konstruktif dalam lingkup PRAMA",
		instagram: "https://instagram.com/prama.ajb",
		activities: [
			{
				title: "Mahasiswa Pejuang Alquran (MPA)",
				desc: "Mahasiswa Pejuang Al-Qur’an merupakan kegiatan menghafal Al-Quran atau sering disebut Tahfidzul Quran yang ditujukan kepada para mahasiswa sekitar kampus Jatinangor dengan peserta terbatas.",
			},
			{
				title: "Iktikaf",
				desc: "Iktikaf merupakan kegiatan berdiam diri di masjid dengan tata cara khusus disertai dengan niat. Program ini direncanakan untuk memfasilitasi para jamaah melalui kegiatan terstruktur yang meliputi pendataan jamaah yang hendak beriktikaf, sholat qiyamul lail, kajian malam (tentatif), serta pembagian sahur bagi jamaah yang terdata. Untuk program Iktikaf ini dikoordinasikan oleh Divisi Iktikaf dari PRAMA.",
			},
			{
				title: "LENIRA (Lentera Inspirasi Ramadhan)",
				desc: "Kegiatan ini merupakan kegiatan yang berupa kajian rutin menjelang berbuka. Kajian ini akan berfokus pada pengenalan dan penjabaran penting keutamaan Bulan Ramadhan serta tema-tema yang sesuai dengan kehidupan manusia dengan mengundang pembicara-pembicara yang mumpuni. Kajian dilakukan berselang dan diiringi  dengan pemberian lomba resume bagi jamaah yang mengikuti kajian. Kajian dapat berupa tematik maupun kajian rutin kitab.",
			},
		],
		gallery: [
			{
				img: "1.jpg",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.jpg",
			},
		],
		ketua: "Regenerasi tiap tahun hijriah",
		pembina: "Mahasiswa",
	},
	{
		id: "19",
		title: "Muslimah Living",
		slug: "muslimahliving",
		logo: "muslimah.jpg",
		video: "",
		profile:
			"Sebuah Platform berbasis pengembangan diri yang terbuka bagi muslimah tanpa ada batasan apapun. Muslimah Living sebagai wadah bagi perempuan generasi muslimah yang ingin berkontribusi bagi kemajuan Indonesia dengan mengedepankan iman, akhlak, ilmu dan amal dengan tetap memperhatikan peran dan fitrahnya sebagai muslimah demi masa depan Indonesia yang lebih baik. Kami percaya Muslimah Living adalah tempat untuk belajar, berinovasi, berkarya dan berkolaborasi untuk membantu memajukan muslimah muda Indonesia dalam menyongsong Indonesia emas 2045.",
		vision:
			"Menjadi wadah pengambangan kepemudaan muslimah yang berdampak melalui karya dengan landasan iman, ilmu, akhlak, dan amal dalam mengakselerasi tercapainya transformasi masyarakat perempuan yang madani dalam menyonsong Indonesia Emas 2045.",
		mission:
			"Mengembangkan potensi iman, akhlak, ilmu dan amal bagi pemuda muslimah, Membina muslimah yang memiliki semangat berkontribusi dalam membangun masyarakat, Menjadi ruang berkarya dan inovasi bagi perempuan penggerak peradaban, Menginspirasi pemuda lainnya dengan menciptakan dampak positif di sekitar, Usaha-usaha lain yang tidak bertentangan dengan nilai Muslimah Living",
		instagram: "https://www.instagram.com/muslimah.living/",
		activities: [
			{
				title: "Agenda Rutin Harian",
				desc: "Reminder ibadah spiritual, Mutabaah Amalan Yaumiah, Sharing Info aatau insight tentang berbagai topik (Sesuai Value Muslimah Living), Diferensiasi Konten Sosial Media Terjadwal",
			},
			{
				title: "Agenda Rutin Mingguan",
				desc: "Reading/Movie Day - Diskusi buku dan film (Live IG), Info Kajian, Beasiswa, Karir, atau yang lannya, Muslimah Living Media, Sharing Bersama Muslimah",
			},
			{
				title: "Agenda Rutin Bulanan",
				desc: "Olahraga Bareng, Muslimah Online Class, Open Submission Muslimah Berkarya, Muslimah Friday Charity, Kajian Tematik Al - Quran, Kajian Umum, Muslimah Goes To School"
			},
			{
				title: "Agenda Rutin Tahunan",
				desc: "Muslimah Camp, Tebar Hijab, Muslimah Youth Summit - Business Expo, conference and competition, Buka Bersama (Ramadhan 1446 H)",
			},
		],
		gallery: [
			{
				img: "1.HEIC",
			},
			{
				img: "2.jpg",
			},
			{
				img: "3.jpg",
			},
			{
				img: "4.jpg",
			},
			{
				img: "5.HEIC",
			},
			{
				img: "6.HEIC",
			},
		],
		ketua: "Aulia Maulidiyah",
		pembina: "Muslimah",
	},
];
